import React from "react";
import logo from "../../img/logo.png";
import { AiOutlineTwitter} from "react-icons/ai";
import {FaFacebookF, FaLinkedinIn} from "react-icons/fa";
import {BiLogoGmail, BiLogoInstagramAlt} from "react-icons/bi";
const Header = () => {
  return (
    <header className="fixed-top">
      <div className="container">

        <div className="d-flex justify-content-between  ">

         <div> </div>


        <div className="social">
          <ul>
             

            <li>
              <a href="https://www.linkedin.com/company/the-viking-method/?viewAsMember=true" target="_blank">
                <FaLinkedinIn className="icon" />
              </a>
            </li>

            <li>
              <a href="https://www.facebook.com/TheVikingMethodCoach/" target="_blank">
                <FaFacebookF className="icon" />
              </a>
            </li>

            <li>
              <a href="https://www.instagram.com/the_viking_method/" target="_blank">
                <BiLogoInstagramAlt className="icon" />
              </a>
            </li>
          </ul>
        </div>
        </div>
       
      </div>
    </header>
  );
};

export default Header;
