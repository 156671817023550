import React, {useState} from "react";
import $ from 'jquery'

const Footer = () => {

  const [data,setData]= useState({
    name:"",
    email:"",
    message:"",

  })

  const changHandler=(e)=>{
    setData({ ...data, [e.target.name]: e.target.value });

  }


  const submit = (e) => {
    e.preventDefault();

    $(".wait").slideDown();

    $.ajax({
      method: "POST",
      url: `https://formsubmit.co/ajax/viking.method.pt@gmail.com`,
      dataType: "json",
      accepts: "application/json",
      data: {
        name: data.name,
        email: data.email,      
        message: data.message,
         
      },
      success: (data) => {
        console.log("success");
        $(".wait").slideUp();
        $(".thanks").slideDown().delay(1000).slideUp()
        setData({
          name:"",
          email:"",
          message:""
        })
      },
      error: (err) => {
        console.log("error");
      },
    });
  };

  return (
    <footer id="contact" >
      <form onSubmit={submit} >

      <div className="form">

<div className="container">

  <h3 className="contact-heading">
    Contact Us
  </h3>
  <div className="row">
    <div className="col-md-6 my-3">
      <input
        type="text"
        name="name"
         value={data.name}
        required
        className="custom-field"
        placeholder="Name"
        onChange={changHandler}
      />
    </div>

    <div className="col-md-6 my-3">
      <input
        type="email"
        name="email"
        value={data.email}
        required
        onChange={changHandler}

        className="custom-field"
        placeholder="Email"
      />
    </div>

    <div className="col-md-12 my-3">
      <textarea
        name="message"
        onChange={changHandler}

        required
        rows="7"
        value={data.message}
        className="custom-field"
      ></textarea>
    </div>

    <p className="text-danger wait hide">Please wait...</p>
    <p className="thanks text-white hide">Thanks for contacting. We will contact you soon! </p>

    <div className="col-md-12 my-3 text-end">
      <button className="submit" >Submit</button>
    </div>
  </div>
</div>
</div>
      </form>
      

      <div className="copy">
      <p>© Tee Viking Method 2023 All rights reserved. Powered with </p>
      </div>


    </footer>
  );
};

export default Footer;
