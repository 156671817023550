import React, { useEffect, useState } from "react";

import aos from "aos";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Stars from "../Stars";

const Home = () => {
  const [activeContent, setActiveContent] = useState("content1");

  const handleCardMouseOver = (id) => {
    setActiveContent(id);

    const contentBoxes = document.querySelectorAll(".contentBox");
    contentBoxes.forEach((box) => {
      box.classList.remove("active");
    });

    document.getElementById(id).classList.add("active");

    const cards = document.querySelectorAll(".card");
    cards.forEach((card) => {
      card.classList.remove("active");
    });

    const activeCard = document.querySelector(`.card[data-id="${id}"]`);
    activeCard.classList.add("active");
  };

  useEffect(() => {
    aos.init({
      duration: 2000,
    });
  }, []);

  useEffect(() => {
    const slider = document.getElementById("preview-slider");
    const image = document.getElementsByClassName("image")[1];
    const buttonRange = document.getElementsByClassName("buttonRange")[0];

    const handleSliderChange = (e) => {
      const sliderPos = e.target.value;
      image.style.width = sliderPos + "%";
      buttonRange.style.left = sliderPos + "%";
    };

    slider.addEventListener("input", handleSliderChange);

    return () => {
      slider.removeEventListener("input", handleSliderChange);
    };
  }, []);

  return (
    <>
      <div className="banner">
        <div className="border-1"></div>
        <div className="border-2"></div>
        <div className="border-3"></div>
        <div className="border-4"></div>

        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="banner-content">
                <h3 className="small-heading" data-aos="zoom-in-up">
                  Health & Wellness
                </h3>
                <h1
                  className="large-heading"
                  data-aos="zoom-in-up"
                  data-aos-delay="200"
                >
                  <span>Simplified</span>{" "}
                </h1>

                <div
                  className="banner-link-cont"
                  data-aos="zoom-in-up"
                  data-aos-delay="400"
                >
                  <a href="#contact" className="banner-link">
                    Join The Movement
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-5 text-center mt-5 mt-lg-0"></div>
          </div>
        </div>
      </div>

      <div className="logo-bg">
        <div className="continer">
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
              
            </div>
          </div>
        </div>
      </div>

      <div className="about-viking pt-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
            <img
                src={require("../../img/mock-up.png")}
                alt="viking Process"
                className="img-fluid"
                data-aos="zoom-in-up"
              />
            </div>
            <div className="col-xl-6 mt-4 mt-xl-0 d-flex flex-column justify-content-center">
              <h1 className="heading-one" data-aos="fade-up">
                What Is The <span>Viking</span> Method?
              </h1>
              <p data-aos="fade-up" data-aos-delay="200">
                This is a training approach developed to streamline the
                fundamental attributes of a Viking warrior, encompassing
                strength, a robust physique, and overall physical prowess. The
                concept was devised with the intention of aiding thousands in
                transforming their physical appearance and well-being. Despite
                the presence of fitness centers globally, frequented by
                individuals engaging in daily workouts, many experience stagnant
                progress and minimal actual transformation. 'The Viking Method'
                was formulated to address the prevalent misconception that mere
                gym attendance guarantees noticeable advancements. The primary
                focus was on crafting a customizable, comprehensible, and
                practical regimen, prioritizing efficiency. This initiative
                stands as a beacon of empowerment for fitness enthusiasts
                worldwide who seek clear guidance in their journey.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="me">
        <div className="container">
          <div className="row">
            <div
              data-aos="zoom-in-up"
              className="col-lg-6 d-flex flex-column justify-content-center"
            >
              <h1 className="heading-white">
                <span>Who</span> AM <span>I</span>
              </h1>

              <p className="white-p">
                I am <span>Matthew Laurenson</span>, the founder and leader of
                'The Viking Method.' My journey as a sports enthusiast began
                with football during my youth, and I later delved into
                competitive golf, achieving the status of a regional junior
                champion. Eventually, my path led me to the world of MMA (Mixed
                Martial Arts), where I competed at a professional level.
              </p>

              <p className="white-p">
                My immersion in various sports at a competitive level exposed me
                to numerous coaches specializing in both strength and
                conditioning, as well as specific disciplines within individual
                sports. Over time, my inclination towards coaching naturally
                evolved. Guided by a passion for aiding others and sharing the
                knowledge that propelled me to achieve my personal best, I
                embarked on a coaching career.
              </p>

              <p className="white-p">
                With almost 15 years of coaching experience under my belt, I
                have witnessed countless individuals in the gym, either training
                independently or under the guidance of other instructors,
                diligently following subpar regimens that could never lead them
                to their optimal selves. This realization kindled a desire for
                transformation, motivating the inception of{" "}
                <span>'The Viking Method'</span>.
              </p>
            </div>
            <div className="col-lg-6 mt-4 mt-md-0 d-flex justify-content-center">
              <img
                src={require("../../img/about.png")}
                alt="viking Process"
                className="img-fluid"
                data-aos="zoom-in-up"
              />
            </div>
          </div>
        </div>
      </div>

   

      <div className="life-fitness">

        {/* <h1>the viking method</h1> */}

        <img src={require("../../img/mask-testing.png")} alt="the viking method" className="img-fluid" />
      </div>

     

      <div className="me we-provide">
        <div class="container">
          <div class="row">
            <h1 className="heading-white text-center mb-4">
              <span>WE</span> PROVIDE
            </h1>

            <div
              className="services mb-5 d-flex justify-content-between flex-column-reverse flex-md-row"
              data-aos="zoom-in-up"
            >
              <div>
                <h1 className="combat">
                  Combat <span>sessions</span>{" "}
                </h1>
                <p className="white">In person 121 or small group sessions</p>
              </div>
            </div>

            <div
              className="services d-flex justify-content-between flex-column flex-md-row"
              data-aos="zoom-in-up"
            >
              <div>
                <h1 className="combat text-md-end">
                  The Viking <span>Method</span>{" "}
                </h1>
                <p className="text-md-end white">
                  Subscription based membership
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-viking">
        <div className="container">
          <div className="row">
            <h1 className="test-heading">testimonials</h1>
            <h1 className="heading-two mb-4  " data-aos="fade-up">
                How John has changed using <br /> the <span> Viking </span>{" "}
                method
              </h1>

            <div className="col-lg-6  ">
              <div className="image-container">
                <div className="image" />
                <div className="image" />
                <input
                  type="range"
                  className="preview-slider"
                  name="preview-slider"
                  id="preview-slider"
                  min={1}
                  defaultValue={50}
                  max={100}
                />
                <div className="buttonRange">
                  <AiOutlineArrowLeft className="before" />
                  <AiOutlineArrowRight className="after" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <div className="cards">
                <div
                  className={`card ${
                    activeContent === "content1" ? "active" : ""
                  }`}
                  data-id="content1"
                  onMouseOver={() => handleCardMouseOver("content1")}
                >
                  <img src={require("../../img/user.png")} alt="" />
                  <div>
                    <h3>- John</h3>
                    <p>Office Manager</p>
                  </div>
                  <div class="gradient"></div>
                </div>
                <div
                  className={`card ${
                    activeContent === "content2" ? "active" : ""
                  }`}
                  data-id="content2"
                  onMouseOver={() => handleCardMouseOver("content2")}
                >
                  <img src={require("../../img/user.png")} alt="" />
                  <div>
                    <h3>- Walter</h3>
                    <p>Music Producer</p>
                  </div>
                  <div class="gradient"></div>
                </div>
                <div
                  className={`card ${
                    activeContent === "content3" ? "active" : ""
                  }`}
                  data-id="content3"
                  onMouseOver={() => handleCardMouseOver("content3")}
                >
                  <img src={require("../../img/user.png")} alt="" />
                  <div>
                    <h3>- Calum</h3>
                    <p>Project Engineer</p>
                  </div>
                  <div class="gradient"></div>
                </div>
              </div>
              <div className="content mt-5">
                <div
                  className={`contentBox ${
                    activeContent === "content1" ? "active" : ""
                  }`}
                  id="content1"
                >
                  <h2>It was a great experience!</h2>
                  <Stars />
                  <p>
                    Been with Matti since we came out of Covid lockdown in 2021
                    and can honestly say the difference is phenomenal. Matti
                    took the time to understand my goals and since the first
                    workout I knew they were going to work and help me achieve.
                    Since then, I’ve gone from strength to strength. Despite a
                    few set backs along the way, Matti adapted my workouts to
                    fit my needs to enable me to come back. Would highly
                    recommend Matti to anyone who wants a trainer. Top bloke,
                    top friend, top workouts, top results
                  </p>
                </div>
                <div
                  className={`contentBox ${
                    activeContent === "content2" ? "active" : ""
                  }`}
                  id="content2"
                >
                  <h2>It was an Awesome experience!</h2>
                  <Stars />
                  <p>
                    Top notch training! Understands the goal, improves
                    weaknesses and builds realistic training plans to suit
                    lifestyle without compromise.
                  </p>
                </div>
                <div
                  className={`contentBox ${
                    activeContent === "content3" ? "active" : ""
                  }`}
                  id="content3"
                >
                  <h2>It was a Nice experience!</h2>
                  <Stars />
                  <p>
                    Would recommend to anyone! Very knowledgeable, listens to
                    your goals, and knows the best way to help you achieve them.
                    If you’re thinking about getting an online PT, hire Matti
                    and you will get results!
                  </p>
                </div>
              </div>
            </div>
            <section className="section"></section>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
